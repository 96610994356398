import { navigate as gatsbyNavigate } from 'gatsby'

const removeParam = (url, param) =>{
    const hostExample = 'https://example.com' // utilizo un host de ejemplo solo para construir la url correctamente y saber si tienen parametros de url
    const urlObject = new URL(hostExample + url)
    let params = new URLSearchParams(urlObject.search)
    url = url.replace(urlObject.search, '')
    params.delete(param)

    return `${url}${params.toString() ? '?' : ''}${params.toString()}`

}

export const getUrl = (url) => {
    const hostExample = 'https://example.com/' // utilizo un host de ejemplo solo para construir la url correctamente y saber si tienen parametros de url
    const urlObject = new URL(hostExample + url)
    url = url.replace(urlObject.search, '')
    let urlParams = new URLSearchParams(urlObject.search || window.location.search)
    let param = 'creditApplicationId'
    if(!urlParams.has(param)) {
        const flujo = JSON.parse(sessionStorage.getItem('flujo'))
        let id = flujo?.creditApplicationId
        if(id) {
            urlParams.append(param, id)
        }
    }
    return `${url}${urlParams.toString() ? '?' : ''}${urlParams.toString()}`
}

export const navigate = (url, options) => {
    getUrl(url)
    gatsbyNavigate(getUrl(url), options)
}

export const navigateDelete = (url, del = [], useHelper = true) => {
    let urlNavigate = url
    const hostExample = 'https://example.com/' // utilizo un host de ejemplo solo para construir la url correctamente y saber si tienen parametros de url
    const urlObject = new URL(hostExample + url)
    if(!urlObject.search){
        urlNavigate += window.location.search
    }

    del.forEach(item => {
        urlNavigate = removeParam(urlNavigate, item)
    })

    if(useHelper) {
        navigate(urlNavigate)
    } else {
        gatsbyNavigate(urlNavigate)
    }

}

export const navigateNative = (url) => {
    window.location.href = getUrl(url)
}
