import GatsbyImage from 'gatsby-image'

export default class GatsbyEagerImage extends GatsbyImage {
  constructor(props, context) {
    super(props, context)
    this.isCritical = true
    this.addNoScript = false
    this.state.isVisible = true
  }
}
