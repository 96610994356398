import { css } from '@emotion/react'
import { useStaticQuery, graphql } from "gatsby"
import Img from '../core/GatsbyEagerImage'

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Logo = ({ company }) => {

  const data = useStaticQuery(graphql`
    query {
        allComponentsJson(filter: {component: {eq: "logo"}}) {
          edges {
            node {
              id
              imagenes {
                company
                alt
                height
                src {
                  publicURL
                  childImageSharp {
                    fixed(height: 80, quality: 80) {
                      ...GatsbyImageSharpFixed_withWebp_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }      
  `)

  const heightCss = css`
    height: 100px;
  `
  const image = data.allComponentsJson.edges[0].node.imagenes.find(item => item.company === company)

  return image.src.childImageSharp ? <Img css={heightCss} fixed={image.src.childImageSharp.fixed} alt={image.alt} /> : <img src={image.src.publicURL} alt={image.alt} height={image.height} />
}

export default Logo