import React from 'react'

import { css } from '@emotion/react'
export default function MainCard({ children }) {
    const root = css`
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
            width: 100%;
        `
    return (
        <section css={root}>
            {children}
        </section>
    )
}
